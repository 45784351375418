import React, { useContext, useState } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Box, Fade } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { RefContext } from "contexts/RefContextProvider";

const Topbar: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openLang, setOpenLang] = useState(false);
  const [currentLang, setCurrentLang] = useState(localStorage.getItem("lang") ?? "en");
  const { faqRef } = useContext(RefContext);
  const clickLang = (i: number) => {
    const strs = ["en", "cz"];
    i18n.changeLanguage(strs[i]);
    setCurrentLang(t(strs[i]));
    setOpenLang(false);
    localStorage.setItem("lang", strs[i]);
  };
  return (
    <StyledContainer>
      <Box display="flex">
        <Title display="flex">
          <ul>
            <li>
              <span onClick={() => setOpenLang(!openLang)}>{currentLang}</span>
              <Fade in={openLang}>
                <LangBox>
                  <Box onClick={() => clickLang(0)}>{t("en")}</Box>
                  <Box onClick={() => clickLang(1)}>{t("cz")}</Box>
                </LangBox>
              </Fade>
            </li>
          </ul>
          <Link to="/">
            <img src="images/logo-small.svg" alt="" />
          </Link>
        </Title>
        <PCMenu>
          <button onClick={() => setOpen(!open)}>
            <Fade in={open}>
              <Box>
                <Box onClick={() => history.push("/")}>{t("home")}</Box>
                <Box onClick={() => faqRef.current.scrollIntoView({ behavior: "smooth" })}>{t("faq")}</Box>
                <Box onClick={() => history.push("/mynfts")}>{t("mynfts")}</Box>
              </Box>
            </Fade>
          </button>
          <ul>
            <li>
              <a
                href="mailto:info@imucha.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("email")}
              </a>
            </li>
            <li>
              <a
                href="https://imucha.com/#news"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("news")}
              </a>
            </li>
            <li>
              <a
                href="#faq"
                onClick={() =>
                  faqRef.current.scrollIntoView({ behavior: "smooth" })
                }
              >
                {t("faq")}
              </a>
            </li>
            <li>
              <a
                href="https://imucha.com/#newsletter"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("newsletter")}
              </a>
            </li>
            <li>
              <a
                href="https://imucha.com/#projects"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("tickets")}
              </a>
            </li>
            <li>
              <a
                href="https://imucha.com/eshop"
                target="_blank"
                rel="noreferrer"
              >
                {t("eshop")}
              </a>
            </li>
            <li>
              <button onClick={() => history.push("/mynfts")}>
                {t("mynft")}
              </button>
            </li>
            <li>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setOpenLang(!openLang)}
              >
                {currentLang}
              </span>
              <Fade in={openLang}>
                <LangBox>
                  <Box onClick={() => clickLang(0)}>{t("en")}</Box>
                  <Box onClick={() => clickLang(1)}>{t("cz")}</Box>
                </LangBox>
              </Fade>
            </li>
          </ul>
        </PCMenu>
      </Box>
    </StyledContainer>
  );
};

const LangBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  background: black;
  border: 1px solid grey;
  color: white;
  box-sizing: content-box;
  width: max-content;
  line-height: 24px;
  > div {
    cursor: pointer;
    padding: 4px 16px;
    transition: 0.3s;
    width: 100%;
    &:hover {
      background: grey;
    }
  }
`;

const PCMenu = styled(Box)`
  > ul {
    margin: 0;
    padding: 0;
    height: 5rem;
    @media print, screen and (min-width: 68em) {
      height: 4.5rem;
    }
    display: none;
    @media print, screen and (min-width: 68em) {
      display: inline-block;
    }
    > li {
      list-style: none;
      border-bottom: 1px solid #fff;
      line-height: 3;

      display: inline-block;
      border-bottom: none;
      margin-right: 0.5rem;
    }
    > li a {
      color: #fff;
    }
    > li:nth-of-type(4):before {
      padding-left: 0.5rem;
      content: "";
      border-left: 1px solid #fff;
      height: 2rem;
      display: inline-block;
      vertical-align: middle;
    }
    > li:nth-of-type(5) {
      > a {
        padding: 0 15px 0 45px;
        background: transparent url(/images/ticket.svg) no-repeat 5px;
        background-size: 1.5rem;
        background-position-x: 15px;
        color: #ffba00;
        z-index: 2;

        border: 2px solid #ffba00;
        border-radius: 10rem;
        line-height: 2rem;
        text-transform: lowercase;

        margin: 1rem 0;
        font-size: 0.9rem;
        display: inline-block;
      }
    }
    > li:nth-of-type(6) {
      > a {
        padding: 0 12px 0 45px;
        background: #ffba00 url(/images/basket.svg) 5px no-repeat;
        background-size: 1.4rem;
        background-position-x: 11px;
        color: #000;

        border: 2px solid #ffba00;
        border-radius: 10rem;
        line-height: 2rem;
        text-transform: lowercase;

        margin: 1rem 0;
        font-size: 0.9rem;
        display: inline-block;
      }
    }
    > li:nth-of-type(7) {
      > button {
        padding: 2px 10px 0;
        border: 2px solid #f03;
        border-radius: 10rem;
        line-height: 1.8rem;
        background-color: #f03;
        color: #000;
        z-index: 2;

        margin: 1rem 0;
        font-size: 0.9rem;
        cursor: pointer;
        display: inline-block;
        &:hover {
          text-decoration: underline;
          text-decoration-color: black;
        }
      }
      &:after {
        padding-right: 1rem;
        content: "";
        border-right: 1px solid #fff;
        height: 2rem;
        display: inline-block;
        vertical-align: middle;
      }
    }
    > li:nth-of-type(8) {
      color: white;
      text-transform: uppercase;
      position: relative;
      > span:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: white;
      }
    }
  }
  > button {
    position: absolute;
    top: 0;
    left: 1rem;
    background: transparent url(/images/menu-burger.svg) 50% no-repeat;
    background-size: 3rem;
    width: 3rem;
    height: 5rem;
    padding: 1rem 0;
    border: none;
    @media print, screen and (min-width: 68em) {
      display: none;
    }
    @media print, screen and (min-width: 40em) {
      padding: 1rem 0;
      right: 2rem;
    }
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      top: calc(100% + 20px);
      left: 0;
      border: 1px solid grey;
      color: white;
      box-sizing: content-box;
      width: max-content;
      > div {
        cursor: pointer;
        padding: 4px 16px;
        transition: 0.3s;
        background: black;
        width: 100%;
        text-align: left;
        &:hover {
          background: grey;
        }
      }
    }
  }
`;

const Title = styled(Box)`
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  @media print, screen and (min-width: 68em) {
    margin: unset;
  }
  > ul {
    margin: 0;
    padding: 0;
    @media print, screen and (min-width: 68em) {
      display: none;
    }
    > li {
      list-style: none;
      line-height: 3;

      text-transform: uppercase;
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 10000;
      padding: 0 1rem;
      border: none;
      color: white;
      > span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      &:after {
        content: "";
        height: 0.5rem;
        width: 0.5rem;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 2px;
        transform-origin: 50% 50%;
        transform: rotate(45deg) translateY(-0.25rem);
      }
    }
  }
  > a {
    height: 5rem;
    @media print, screen and (min-width: 68em) {
      height: 4.5rem;
    }
    > img {
      height: 100%;
      padding: 1rem;
      @media print, screen and (min-width: 40em) {
        padding: 1rem 2rem;
      }
    }
  }
  > div {
    display: none;
    @media print, screen and (min-width: 68em) {
      display: inline-block;
      cursor: pointer;
    }
    &:after {
      @media print, screen and (min-width: 68em) {
        content: "";
        height: 0.5rem;
        width: 0.5rem;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 2px;
        transform: rotate(45deg) translateY(-0.25rem);
      }
    }
  }
`;

const StyledContainer = styled(Box)`
  position: absolute;
  z-index: 1;
  width: 100%;
  > div {
    justify-content: space-between;
    @media print, screen and (min-width: 68em) {
      padding-right: 0;
      padding-left: 0;
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export default Topbar;

import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Box, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { setOpenCookieModal } from "redux/reducers/ModalReducer";

const CookieModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const open = useSelector(
    (store: any) => store.ModalReducer.openCookieModal
  );

  const close = async () => {
    dispatch(setOpenCookieModal({}));
  };

  return (
    <Modal open={open} onClose={close} disableScrollLock>
      <StyledContainer>
        <Title mb={2}>
          <img src='images/cookie2.png' width='40' height='40' alt='' />
          <h2>{t("cookie.title")}</h2>
        </Title>
        {t("cookie.welcomeText")}
      </StyledContainer>
    </Modal>
  );
};

const Title = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  >h2 {
    margin-left: 10px;
  }
`

const StyledContainer = styled(Box)<any>`
  outline: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #edefef;

  display: flex;
  flex-direction: column;
  min-width: 360px;
  padding: 40px;
  @media (max-width: 768px) {
    min-width: unset;
    width: calc(100% - 60px);
    padding: 20px;
  }
  max-height: calc(100% - 100px);
  overflow-y: auto;
  > svg {
    cursor: pointer;
    position: absolute;
    right: 13.9px;
    top: 12.1px;
  }
`;

export default CookieModal;

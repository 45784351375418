import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'react-i18next'
import { connectorsByName } from '../utils/web3React'

const useAuth = () => {
  const { activate, deactivate } = useWeb3React()
  const { t } = useTranslation()

  const login = (conectorID:any) => {
    const connector = connectorsByName[conectorID]
    if (connector) {
      activate(connector, (error: Error) => console.log(error.name, error.message))
    } else {
      alert(t("msg.wrongConnector"))
    }
  }

  return { login, logout: deactivate }
}

export default useAuth
import { configureStore } from '@reduxjs/toolkit';

import ModalReducer from 'redux/reducers/ModalReducer';
import NetworkReducer from 'redux/reducers/NetworkReducer';

export const store = configureStore({
    reducer: {
      ModalReducer,
      NetworkReducer,
    },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux'

import App from './App';
import Providers from './Providers'
import GlobalStyle from './style/Global';
import RefContextProvider from 'contexts/RefContextProvider';
import { store } from './redux'

ReactDOM.render(
  <StoreProvider store={store}>
    <RefContextProvider>
      <Providers>
        <GlobalStyle />
        <App />
      </Providers>
    </RefContextProvider>
  </StoreProvider>,
  document.getElementById('root')
);
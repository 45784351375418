import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { RefContext } from "contexts/RefContextProvider";

const CollectionInfo: React.FC = () => {
  const { t } = useTranslation();
  const { collectionInfoRef } = useContext(RefContext);

  return (
    <StyledContainer component="section" ref={collectionInfoRef}>
      <Box>
        <h2>{t("collectionInfo.title")}</h2>
        <Text1 component="p">{t("collectionInfo.content")}</Text1>
      </Box>
    </StyledContainer>
  );
};

const Text1 = styled(Box)`
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0.75rem;
  white-space: pre-wrap;
  a {
    color: #fff;
    font-weight: bold;
    letter-spacing: 0.03em;
  }
`;

const StyledContainer = styled(Box)`
  padding-top: 6rem;
  padding-bottom: 6rem;
  background: black;
  > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
    padding-left: 2rem;
    padding-right: 2rem;
    @media (min-width: 1024px) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    > h2 {
      margin-bottom: 1rem;
      line-height: 1.75rem;
      font-size: 1.25rem;
      font-weight: 700;
      color: white;
      @media (min-width: 768px) {
        font-size: 1.875rem;
        line-height: 2.25rem;
      }
    }
  }
`;

export default CollectionInfo;

export const TRANSLATIONS_CZ = {
  policy: {
    title: "Podmínky",
    content: `Prohlašuji, že jsem četl, rozuměl a souhlasím s <0>Obchodními podmínkami</0> (dále jen „OP“).<br/><br/>
    Dále prohlašuji, že nejsem občanem, rezidentem ani společností založeným nebo registrovaným do žádné z zakázaných jurisdikcí, jak je definováno v OP.<br/><br/>
    Rovněž prohlašuji, že v případě jakéhokoli nákupu NFT prostřednictvím webové stránky výslovně souhlasím s okamžitým doručením digitálního obsahu před vypršením období odstoupení, tj. Před vypršením období 14 dnů ode dne uzavření smlouvy a tímto beru na vědomí, že nemůžu uplatnit své právo odstoupit od uzavřené smlouvy na dodávku digitálního obsahu z tohoto důvodu v období 14 dnů.<br/><br/>
    Prohlašuji také, že jsem si přečetl <1>oznámení o ochraně osobních údajů</1>.`,
    accept: "Akceptovat",
    reject: "Odmítnout",
  },
  cookie: {
    title: "Soubory cookie",
    details: "Podrobnosti",
    acceptButton: "Přijmout všechny soubory cookie",
    declineButton: "Zamítnout",
    description: "Tento web používá soubory cookie ke zlepšení uživatelského prostředí.",
    welcomeText: "Použití cookies nám umožňuje nabídnout vám obsah a služby, které nejlépe odpovídají vašim potřebám a zájmům. Cookies umožňuje zaznamenávat informace o vaší návštěvě webu a díky jejich použití bude vaše další návštěva snazší a rychlejší.",
  },
  en: "en",
  cz: "cz",
  home: "Home",
  email: "E-mail",
  news: "Novinky",
  faq: "FAQ",
  newsletter: "Newsletter",
  tickets: "Vstupenky",
  eshop: "E-shop",
  mynfts: "Moje NFT",
  mynft: "Moje NFT",
  introduce: {
    title: "KOLEKCE NFT",
    description: "Velkolepé dílo Alphonse Muchy dostalo díky moderní technologii nový rozměr.",
  },
  collections: {
    0: {
      title: "Kolekce Plakáty",
      subtitle: {
        0: "Krásné animované plakáty",
        1: "Použití standardu CallistoNFT",
        2: "Více úrovní NFT s různými <0>výhodami</0>",
        3: "<0>Přečíst více o kolekci Plakáty</0>",
      }
    },
    1: {
      title: "Kolekce Slovanská epopej",
      subtitle: {
        0: "K zakoupení na OpenSea",
        1: "Majitelé NFT získají přístup do Emerald Clubu, kde mohou čerpat výhody spojené s tímto členstvím.",
        2: "Pouze jedno NFT pro každý obraz",
      }
    },
  },
  buyNow: "Koupit",
  connect: "Připojte Peněženku",
  disconnect: "Odpojit",
  posters: {
    title: "Kolekce Plakáty",
    description: "NFT na blockchainu <0>callisto.network</0>",
  },
  buyEmerald: "Koupit Emerald",
  buyRuby: "Koupit Ruby",
  buyAmethyst: "Koupit Ametyst",
  exhibitionExclusive: "Pouze na výstavě",
  collectionInfo: {
    title: "O kolekci Plakáty",
    content: `Světoznámé plakáty Alfonse Muchy dostaly díky moderním technologiím nový rozměr. Dívky z plakátů ve stylu art nouveau ožily a vy tak můžete obdivovat jejich krásu v nejlepší kvalitě na velkoplošné obrazovce u vás doma.\n\nV této NFT kolekci najdete 5 rozpohybovaných plakátů Alfonse Muchy. Jednotlivé animace vyzdvihují energii a krásu, kterou se podařilo Alfonsi Muchovi vytvořit před více než 100 lety.\n\nKaždá animace je propracovaná do nejmenšího detailu, ale zároveň je zachována jedinečná nálada jednotlivých plakátů. Drobné pohyby v animacích jsou vytvořeny velice citlivě aby nerušily náladu jednotlivých děl.\n\nNFT z této kolekce jsou nabízena na vlastním NFT marketu, který je postaven na blockchainu Callisto Network. Tento blockchain byl zvolen z důvodu vysoké bezpečnosti a možnosti prodávat NFT v jednotlivých edicích. Toto je umožněno díky nízkým poplatkům na síti a novému CallistoNFT standardu. NFT jsou nabízena ve 3 různých edicích, jejichž názvy vycházejí z Muchových plakátů: Emerald, Ruby a Amethyst. NFT z této kolekce je možné zakoupit za stanovenou cenu na našem NFT marketu. Nabídka není časově omezena. Omezeno je pouze množství NFT v každé edici.\n\nPro vlastníky NFT jsou připraveny různé výhody dle příslušné edice. Například vlastníci nejvyšší Emerald edice získávají přístup do Emerald clubu, kde mohou čerpat výhody navázané na toto členství. Mohou se tak například zdarma účastnit veškerých výstav a akcí pořádaných iMucha projektem. Dále získávají slevu na nákup v e-shopu iMucha. Detailní popis výhod pro jednotlivé edice a podmínky jejich využití je pak k nalezení na webu nft.muchaemotion.com.`,
  },
  faqs: {
    title: "Často kladené otázky",
    subtitle: {
      0: "Jak získat CLO (nativní kryptoměnu Callisto Network)",
      1: "Jak koupit (vymintovat) NFT?",
      2: "Potřebuji nějakou registraci?",
      3: "Kde mohu vidět své NFT?",
      4: "Mohu převést své NFT na jinou adresu?",
      5: "Smart contract - informace",
      6: "Výhody a využití NFT",
    },
    content: {
      0: "1) CLO si můžete koupit přímo pomocí nejbezpečnějšího <0>SOY Finance Bridge</0> s ETH, BNB, ETC, USDT, BTT, CAKE, SHIB viz video průvodce <1>zde.</1>",
      1: "2) Můžete si snadno <0>koupit BNB svou kreditní kartou</0> a poté použít <1>bridge</1> k nákupu CLO coinů (nebojte se, je to opravdu snadné)",
      2: "3) <0>CLO</0> můžete získat na Bitfinexu nebo můžete využít nejzabezpečenější decentralizovanou burzu <1>SOY Finance.</1>",
      3: "Potřebujete peněženku Metamask a dostatek mincí Callisto (CLO), na nákup NFT. Vše je popsáno v Jak získat CLO.",
      4: "Ne, stačí připojit peněženku a začít nakupovat :).",
      5: `Své NFT si můžete prohlédnout a stáhnout na stránce “Moje NFT”. Vaše NFT jsou ve vaší peněžence, ART token můžete vidět, pokud vyhledáte svou adresu na explorer.callisto.network. V tuto chvíli tým Callisto pracuje na implementaci NFT přímo do průzkumníka. NFT by měly být viditelné také v aplikaci Absolute wallet.`,
      6: `Ano, můžete použít tlačítko “Poslat” na stránce “Moje NFT”. Když odesíláte NFT, ujistěte se, že jste připojeni k síti Callisto, jinak se NFT v nové peněžence nezobrazí. Díky bezpečnostním funkcím zabudovaným do nového standardu CallistoNFT nemůžete náhodně ztratit své NFT odesláním na nesprávnou adresu peněženky, což je u jiných standardů NFT riziko.`,
      7: "Blockchain: Callisto Network",
      8: "Standard: Callisto NFT (kompatibilní s ERC-721)",
      9: "Adresa smart-contractu: <0>Callisto Network Explorer</0>",
      10: "Vlastník tohoto NFT se stává členem Emerald Klubu. Člen Emerald klubu získává zdarma vstup na všechny akce pořádané nebo spolupořádané iMucha. Člen Emerald klubu také získává slevu 20 % do e-shopu iMucha <0>https://imucha.com/eshop</0> a další výhody, které budou zveřejňovány na webu iMucha.",
      11: "Vlastník NFT z edice Ruby se stává členem Ruby Klubu. Člen Ruby klubu získává slevu 20 % do e-shopu iMucha <0>https://imucha.com/eshop</0> a další výhody, které budou zveřejňovány na webu iMucha.",
      12: "Vlastník NFT z edice Amethyst se stává členem Amethyst Klubu. Člen tohoto klubu může čerpat výhody, které budou zveřejňovány na webu iMucha.",
      13: "Vlastník tohoto NFT se stává členem Emerald Klubu. Člen Emerald klubu získává zdarma vstup na všechny akce pořádané nebo spolupořádané iMucha. Člen Emerald klubu také získává slevu 20 % do e-shopu iMucha https://imucha.com/eshop a další výhody, které budou zveřejňovány na webu iMucha.",
      14: "Vlastník NFT z edice Ruby se stává členem Ruby Klubu. Člen Ruby klubu získává slevu 20 % do e-shopu iMucha https://imucha.com/eshop a další výhody, které budou zveřejňovány na webu iMucha.",
      15: "Vlastník NFT z edice Amethyst se stává členem Amethyst Klubu. Člen tohoto klubu může čerpat výhody, které budou zveřejňovány na webu iMucha.",
    }
  },
  noItems: "Žádně položky",
  download: "Stáhnout",
  transfer: "Poslat",
  from: "Odesílatel",
  confirm: "Potvrdit",
  name: {
    0: "Monte Carlo (1897)",
    1: "Monte Carlo (1897)",
    2: "Monte Carlo (1897)",
    3: "Karafiát (1898)",
    4: "Karafiát (1898)",
    5: "Karafiát (1898)",
    6: "Léto (1899)",
    7: "Léto (1899)",
    8: "Léto (1899)",
    9: "Dáma s kaméliemi (1896)",
    10: "Dáma s kaméliemi (1896)",
    11: "Dáma s kaméliemi (1896)",
    12: "Kola Perfecta (1902)",
    13: "Kola Perfecta (1902)",
    14: "Kola Perfecta (1902)",
  },
  edition: {
    Emerald: "Smaragd | #<0></0><br/>",
    Ruby: "Rubín | #<0></0><br/>",
    Amethyst: "Ametyst | #<0></0><br/>",
  },
  copyright: "© 2022 Mucha eMOTION",
  footer: {
    terms: "Podmínky",
    privacy: "Soukromí",
  },
  msg: {
    install: "Nainstalujte si prosím Metamask.",
    running: "V současné době běží.",
    gettingPrice: "Nyní zjišťuji cenu.",
    connect: "Připojte prosím peněženku.",
    wrongConnector: "Konfigurace konektoru je chybná.",
  }
};
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import Web3 from "web3";

import Layout from "layouts";
import Landing from "pages/Landing";
import MyNFTs from "pages/MyNFTs";

import useAuth from "hooks/useAuth";
import { connections, connectorLocalStorageKey } from "pages/entry";
import { change } from "utils/web3React";
import { useTranslation } from "react-i18next";
import "translations/i18n";

declare let window: any;

const App = () => {
  const { login: signin, logout: signout } = useAuth();
  const { t } = useTranslation();
  const login = async () => {
    if (window.ethereum) {
      await window.ethereum.enable();
      window.web3 = new Web3(window.ethereum);

      const _chainId = await window.ethereum.request({ method: 'eth_chainId' });
      if(_chainId !== "0x334") await change();
      else {
        await signin(connections[0].connectorId);
        window.localStorage.setItem(connectorLocalStorageKey, connections[0].connectorId);
      }
    } else alert(t("msg.install"));
  };
  useEffect(() => {
    if(window.ethereum) {
      window.ethereum.on("chainChanged", function(networkId: any) {
        document.location.reload()
      });
    }
  }, [])
  return (
    <Router>
      <Switch>
        <Layout>
          <Route exact path="/">
            <Landing login={login} logout={signout} />
          </Route>
          <Route exact path="/mynfts">
            <MyNFTs login={login} logout={signout} />
          </Route>
        </Layout>
      </Switch>
    </Router>
  );
};

export default App;

import React from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CollectionInfo from "./CollectionInfo";
import Posters from "./Posters";
import Collections from "./Collections";
import FAQ from "./FAQ";

import "translations/i18n";

interface Props {
  login: any
  logout: any
}

const Landing: React.FC<Props> = ({ login, logout }) => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <Introduce component="section">
        <img src="images/logo512.png" alt="" />
        <p>{t("introduce.title")}</p>
        <p>{t("introduce.description")}</p>
      </Introduce>
      <Collections login={login} logout={logout} />
      <Posters />
      <CollectionInfo />
      <FAQ />
    </StyledContainer>
  );
};

const Introduce = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
  * {
    color: white;
  }
  @media (min-width: 640px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0;
    padding-top: 0;
  }
  > img {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  > p:first-of-type {
    margin-top: 0.25rem;
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 800;
    @media (min-width: 640px) {
      font-size: 3rem;
      line-height: 1;
      letter-spacing: -0.025em;
    }
    @media (min-width: 1024px) {
      font-size: 3.75rem;
      line-height: 1;
    }
  }
  > p:nth-of-type(2) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.25rem;
    max-width: 48rem;
    line-height: 1.75rem;
    font-size: 1.25rem;
  }
`;

const StyledContainer = styled(Box)``;
export default Landing;

import React, { useRef, useMemo } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TransferModal, CookieModal, PolicyModal } from "components/Modals";
import { useDispatch } from "react-redux";
import { setOpenCookieModal } from "redux/reducers/ModalReducer";
import CookieConsent from "react-cookie-consent";
import PropTypes from "prop-types";
import styled from "styled-components";
import i18n from "i18next";
import Topbar from "./Topbar";
import Footer from "./Footer";

interface Props {
  children?: any;
}

const Layout: React.FC<Props> = ({ children }) => {
  const bodyRef = useRef<any>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useMemo(() => i18n.changeLanguage(localStorage.getItem("lang") ?? "en"), [])
  return (
    <StyledContainer>
      <Topbar />
      <Body component="main" {...{ ref: bodyRef }}>
        {children}
      </Body>
      <CookieConsent
        location="bottom"
        buttonText={t("cookie.acceptButton")}
        cookieName="accepted"
        cookieValue={new Date().toISOString()}
        enableDeclineButton
        style={{ background: "rgba(255,255,255,1)", borderTop: "1px solid grey", boxShadow: "1px 1px 9px 1px black", color: '#111' }}
        buttonStyle={{ background: "rgba(253, 187, 42, 1)", color: 'white', fontWeight: 700, fontSize: "14px", borderRadius: '9999px', padding: '0.5rem 1rem' }}
        declineButtonText={t("cookie.declineButton")}
        declineButtonStyle={{background: "#111", color: 'rgba(255,255,255,1)', fontWeight: 700, fontSize: "14px", borderRadius: '9999px', padding: '0.5rem 1rem'}}
        expires={180}
      >
        <CookieContent display='flex' alignItems='center'>
          {t("cookie.description")}
          <span onClick={() => dispatch(setOpenCookieModal({}))}>{t("cookie.details")}</span>
        </CookieContent>
      </CookieConsent>
      <Footer />
      <TransferModal />
      <CookieModal />
      <PolicyModal />
    </StyledContainer>
  );
};

const CookieContent = styled(Box)`
  >span {
    cursor: pointer;
    margin-left: 16px;
    text-decoration-color: #111;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
`

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  .CookieConsent {
    align-items: center !important;
    button:hover {
      text-decoration: underline;
      text-decoration-color: white;
    }
    @media (max-width: 720px) {
      flex-direction: column;
      >div:first-of-type {
        flex: unset !important;
        text-align: center;
      }
      >div:nth-of-type(2) {
        justify-self: flex-end !important;
      }
    }
    @media (max-width: 400px) {
      >div:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        button {
          margin: 0 !important;
          margin-bottom: 16px !important;
        }
      }
    }
  }
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

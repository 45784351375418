import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    wrongNetwork: true,
    accepted: false,
}

export const NetworkReducer = createSlice({
    name: 'network',
    initialState,
    reducers: {
        setWrongNetwork: (state, action) => {
            state.wrongNetwork = action.payload.wrong
        },
        setAccepted: (state, action) => {
            state.accepted = action.payload.accepted
        },
    },
})

export const { setWrongNetwork, setAccepted } = NetworkReducer.actions

export default NetworkReducer.reducer
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import web3 from "web3";
import _ from "lodash";
import Multicall from "@dopex-io/web3-multicall";
import { useDispatch, useSelector } from "react-redux";
import { useAuctionContract, useMainContract } from "hooks/useContract";
import { setOpenTransferModal } from "redux/reducers/ModalReducer";
import Spinner from "components/Spinners";

interface Props {
  login: any
  logout: any
}

const MyNFTs: React.FC<Props> = ({ login, logout }) => {
  const { account } = useWeb3React();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const mainContract = useMainContract();
  const auctionContract = useAuctionContract();
  const details = useSelector((store: any) => store.ModalReducer.details);
  const [mynfts, setMyNFTs] = useState<any>([]);
  const auth = async () => {
    if (!account) login();
    else {
      logout();
      setMyNFTs([]);
      setLoading(false);
    }
  };
  const saveManual = async (link: any) => {
    setDownloading(true);
    try {
      // await fileSaver.saveAs(link, "video.mp4");
      window.open(link, "_blank");
    } catch (e) {
      console.log(e)
    }
    setDownloading(false);
  }
  const load = useCallback(async () => {
    if (account) {
      setLoading(true);
      try {
        const nextMintId = await mainContract?.next_mint_id().call();
        const multicall = new Multicall({
          multicallAddress: "0x914D4b9Bb542077BeA48DE5E3D6CF42e7ADfa1aa",
          provider: web3.givenProvider,
        });
        let multicallArray1: any = [];
        let multicallArray2: any = [];
        let multicallArray3: any = [];
        let multicallArray4: any = [];
        let multicallArray5: any = [];
        let multicallArray6: any = [];

        for (let i = 0; i < nextMintId; i++) {
          multicallArray1.push(mainContract?.ownerOf(i));
          multicallArray2.push(mainContract?.getClassPropertyForTokenID(i, 0));
          multicallArray3.push(mainContract?.getClassPropertyForTokenID(i, 1));
          multicallArray4.push(mainContract?.getTokenProperties(i));
          multicallArray5.push(mainContract?.getClassForTokenID(i));
        }

        const multiResult1 = await multicall.aggregate(multicallArray1);
        const multiResult2 = await multicall.aggregate(multicallArray2);
        const multiResult3 = await multicall.aggregate(multicallArray3);
        const multiResult4 = await multicall.aggregate(multicallArray4);
        const multiResult5 = await multicall.aggregate(multicallArray5);

        const owners = _.map(multiResult1, (x: any, i: number) => {
          let details1 = {};
          let details2 = {};
          try {
            details1 = JSON.parse(multiResult2[i]);
            details2 = JSON.parse(multiResult3[i]);
          } catch (e) {
            console.log("Wrong format in token id:", i, e);
          }
          return {
            tokenID: i,
            owner: x,
            details1,
            details2,
            serialNumber: multiResult4[i]["properties"][1],
            classID: parseInt(multiResult5[i]),
          };
        });

        for (let j = 0; j < owners.length; j++)
          multicallArray6.push(auctionContract?.auctions(owners[j].classID));
        const multiResult6 = await multicall.aggregate(multicallArray6);
        const ownersWithTrade = _.map(owners, (x: any, i: number) => {
          return {
            ...x,
            trade: multiResult6[i],
          };
        });

        const mine = ownersWithTrade.filter(
          (x: any) => x.owner.toLowerCase() === account.toLowerCase()
        );
        console.log("mine", mine);
        setMyNFTs(mine);
      } catch (e) {
        console.log(e)
      }
      setLoading(false);
    }
  }, [account, mainContract, auctionContract]);

  useMemo(() => load(), [load]);
  useMemo(async () => {
    if (details.transferred) load();
  }, [details.transferred, load]);
  return (
    <StyledContainer>
      <Box display="flex" justifyContent="flex-end" flexWrap="wrap" mb={2}>
        <BuyBox connected={account ? 1 : 0}>
          <button onClick={auth}>
            {!account ? t("connect") : t("disconnect")}
          </button>
        </BuyBox>
      </Box>
      {mynfts.length === 0 && loading === false && (
        <Typography style={{ color: "white", textAlign: "center" }}>
          {t("noItems")}
        </Typography>
      )}
      <Grid>
        {loading ? (
          <Spinner />
        ) : (
          _.map(mynfts, (x: any, i: number) => (
            <Item key={i}>
              <video controls autoPlay loop muted>
                <source src={x.details1.image_ipfs} type="video/mp4" />
              </video>
              <Box>
                <Trans i18nKey={`name.${x.classID}`} />
              </Box>
              <Box component="h5" color="#777" textAlign="center">
                <Trans i18nKey={`edition.${x.details2.edition}`}>
                  {[x.serialNumber]}
                </Trans>
              </Box>
              <Actions>
                <BuyBox>
                  <button onClick={() => saveManual(x.details1.image_ipfs)} disabled={downloading}>
                    {t("download")}
                  </button>
                </BuyBox>
                <BuyBox>
                  <button onClick={() => dispatch(setOpenTransferModal({ tokenID: x.tokenID }))}>
                    {t("transfer")}
                  </button>
                </BuyBox>
              </Actions>
            </Item>
          ))
        )}
      </Grid>
    </StyledContainer>
  );
};

const Actions = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  >div+div {
    margin-top: 8px;
  }
`;

const BuyBox = styled(Box)<any>`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: right;
  @media (min-width: 768px) {
    padding-top: 0.5rem;
  }
  @media (min-width: 768px) {
    padding-bottom: 0.5rem;
  }
  > button {
    border-radius: 9999px;
    background: ${({ connected }) =>
      connected ? "rgba(255,255,255,1)" : "rgba(253, 187, 42, 1)"};
    color: ${({ connected }) => (connected ? "black" : "white")};
    text-decoration-color: ${({ connected }) => (connected ? "black" : "white")};
    transition: background, color 0.5s;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: 700;
    border: none;
    outline: none;
    &:hover:not(:disabled) {
      text-decoration: underline;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

const Item = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 16px;
  gap: 16px;
  > video {
    height: 300px;
  }
`;

const Grid = styled(Box)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
`;

const StyledContainer = styled(Box)`
  padding-top: 100px;
  padding-left: 32px;
  padding-right: 32px;
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  width: 100%;
`;

export default MyNFTs;

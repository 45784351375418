import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    openTransferModal: false,
    openCookieModal: false,
    openPolicyModal: false,
    details: {},
}

export const ModalReducer = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setOpenTransferModal: (state, action) => {
            state.openTransferModal = !state.openTransferModal
            state.details = action.payload
        },
        setOpenCookieModal: (state, action) => {
            state.openCookieModal = !state.openCookieModal
            state.details = action.payload
        },
        setOpenPolicyModal: (state, action) => {
            state.openPolicyModal = !state.openPolicyModal
            state.details = action.payload
        },
    },
})

export const { setOpenTransferModal, setOpenCookieModal, setOpenPolicyModal } = ModalReducer.actions

export default ModalReducer.reducer
import React, { useMemo, useRef } from "react";
import { ClickAwayListener, Tooltip as MuiTooltip, TooltipProps } from "@material-ui/core";

interface Props extends TooltipProps {
    _open: any;
    _setOpen: any;
    _index: number;
}

const Tooltip: React.FC<Props> = (props) => {
    const { _open, _setOpen, _index, children, ...p } = props
    const timeout = useRef<any>(null);
    useMemo(() => {
        clearTimeout(timeout.current)
        if(_open === _index) timeout.current = setTimeout(() => _setOpen(-1), 10000)
    }, [_open, _index, _setOpen])
    return (
        <ClickAwayListener onClickAway={() => _open === _index && _setOpen(-1)}>
            <MuiTooltip open={_open === _index} onClose={() => _setOpen(-1)} disableFocusListener disableHoverListener disableTouchListener enterTouchDelay={0} {...p}>
                {children}
            </MuiTooltip>
        </ClickAwayListener>
    );
};

export default Tooltip;
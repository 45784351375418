import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { useHistory } from "react-router-dom";
import { Contract, Provider, setMulticallAddress } from "ethers-multicall";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import web3 from "web3";
import { ethers } from "ethers";
import { getSupply } from "utils/calc";
import { ROUTERS } from "contracts";
import { setOpenPolicyModal } from "redux/reducers/ModalReducer";
import { useAuctionContract, useMainContract } from "hooks/useContract";
import Spinner from "components/Spinners";
import Tooltip from "components/Tooltips";

const Posters: React.FC<any> = () => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const history = useHistory();
  const dispatch = useDispatch();
  const mainContract = useMainContract();
  const auctionContract = useAuctionContract();
  const [doing, setDoing] = useState(false);
  const [open, setOpen] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [posters, setPosters] = useState<any>([]);
  const accepted = useSelector((store: any) => store.NetworkReducer.accepted);
  const buy = async (classId: Number) => {
    if (account) {
      if (!doing) {
        setDoing(true);
        if (posters.length > 0) {
          if(accepted) {
            const propStr = await mainContract?.getClassProperties(classId).call();
            try {
              const prop = JSON.parse(propStr[1]);
              await auctionContract?.buyNFT(classId).send({ from: account, value: web3.utils.toWei(prop.price) });
              history.push("/mynfts");
            } catch (e: any) {
              if (e.code) alert(JSON.stringify(e));
              else alert(e);
            }        
          } else dispatch(setOpenPolicyModal({ classId }))
        } else alert(t("msg.gettingPrice"));
        setDoing(false);
      } else alert(t("msg.running"));
    } else {
      alert(t("msg.connect"));
    }
  };
  useMemo(async () => {
    const provider = new ethers.providers.JsonRpcProvider("https://rpc.callisto.network");
    const ethcallProvider = new Provider(provider);
    setMulticallAddress(820, "0x914D4b9Bb542077BeA48DE5E3D6CF42e7ADfa1aa");
    await ethcallProvider.init();
    const contract = new Contract(ROUTERS.AUCTION.address, ROUTERS.AUCTION.abi);
    const _mainContract = new Contract(ROUTERS.MAIN.address, ROUTERS.MAIN.abi);

    try {
      let tmp: any = [];
      for (let i = 0; i < 14; i++) tmp.push(contract.auctions(i));
      const postersTemp = await ethcallProvider.all(tmp);

      let imagesTmp: any = [];
      for (let i = 0; i < postersTemp.length; i++) imagesTmp.push(_mainContract?.getClassProperty(i, 0));
      const images = await ethcallProvider.all(imagesTmp);

      setPosters(
        _.map(postersTemp, (x: any, i: number) => {
          return {
            classID: i,
            image: JSON.parse(images[i]).image_ipfs,
            trade: x,
          };
        })
      );
      setLoading(false);
      document.querySelectorAll("video").forEach(x=> x.play())
    } catch(e) {
      console.log(e)
    }
  }, []);
  return (
    <StyledContainer component="section">
      <Box>
        <h2>{t("posters.title")}</h2>
        <p>
          <Trans i18nKey="posters.description">
            {[<a key={0} href="https://callisto.network" target="_blank" rel="noreferrer"> </a>]}
          </Trans>
        </p>
        {loading ? (
          <SpinnerBox><Spinner /></SpinnerBox>
        ) : (
          <Grid>
            <GridItem>
              <Box>
                <video controls autoPlay loop muted playsInline>
                  <source src={posters[0]?.image} type="video/mp4" />
                </video>
              </Box>
              <h4>{t("name.0")}</h4>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[0]?.classID)}>{t("buyEmerald")} - 470 000 CLO</button>
                  <Tooltip title={t("faqs.content.13")} _open={open} _setOpen={setOpen} _index={0}>
                    <img onClick={() => setOpen(open === 0 ? -1 : 0)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[0])}</span>
              </BuyBox>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[1]?.classID)}>{t("buyRuby")} - 47 000 CLO</button>
                  <Tooltip title={t("faqs.content.14")} _open={open} _setOpen={setOpen} _index={1}>
                    <img onClick={() => setOpen(open === 1 ? -1 : 1)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[1])}</span>
              </BuyBox>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[2]?.classID)}>{t("buyAmethyst")} - 4 700 CLO</button>
                  <Tooltip title={t("faqs.content.15")} _open={open} _setOpen={setOpen} _index={2}>
                    <img onClick={() => setOpen(open === 2 ? -1 : 2)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[2])}</span>
              </BuyBox>
            </GridItem>
            <GridItem>
              <Box>
                <video controls autoPlay loop muted playsInline>
                  <source src={posters[3]?.image} type="video/mp4" />
                </video>
              </Box>
              <h4>{t("name.3")}</h4>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[3]?.classID)}>{t("buyEmerald")} - 470 000 CLO</button>
                  <Tooltip title={t("faqs.content.13")} _open={open} _setOpen={setOpen} _index={3}>
                    <img onClick={() => setOpen(open === 3 ? -1 : 3)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[3])}</span>
              </BuyBox>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[4]?.classID)}>{t("buyRuby")} - 47 000 CLO</button>
                  <Tooltip title={t("faqs.content.14")} _open={open} _setOpen={setOpen} _index={4}>
                    <img onClick={() => setOpen(open === 4 ? -1 : 4)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[4])}</span>
              </BuyBox>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[5]?.classID)}>{t("buyAmethyst")} - 4 700 CLO</button>
                  <Tooltip title={t("faqs.content.15")} _open={open} _setOpen={setOpen} _index={5}>
                    <img onClick={() => setOpen(open === 5 ? -1 : 5)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[5])}</span>
              </BuyBox>
            </GridItem>
            <GridItem>
              <Box>
                <video controls autoPlay loop muted playsInline>
                  <source src={posters[6]?.image} type="video/mp4" />
                </video>
              </Box>
              <h4>{t("name.6")}</h4>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[6]?.classID)}>{t("buyEmerald")} - 470 000 CLO</button>
                  <Tooltip title={t("faqs.content.13")} _open={open} _setOpen={setOpen} _index={6}>
                    <img onClick={() => setOpen(open === 6 ? -1 : 6)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[6])}</span>
              </BuyBox>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[7]?.classID)}>{t("buyRuby")} - 47 000 CLO</button>
                  <Tooltip title={t("faqs.content.14")} _open={open} _setOpen={setOpen} _index={7}>
                    <img onClick={() => setOpen(open === 7 ? -1 : 7)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[7])}</span>
              </BuyBox>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[8]?.classID)}>{t("buyAmethyst")} - 4 700 CLO</button>
                  <Tooltip title={t("faqs.content.15")} _open={open} _setOpen={setOpen} _index={8}>
                    <img onClick={() => setOpen(open === 8 ? -1 : 8)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[8])}</span>
              </BuyBox>
            </GridItem>
            <GridItem />
            <GridItem>
              <Box>
                <video controls autoPlay loop muted playsInline>
                  <source src={posters[9]?.image} type="video/mp4" />
                </video>
              </Box>
              <h4>{t("name.9")}</h4>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[9]?.classID)}>{t("buyEmerald")} - 470 000 CLO</button>
                  <Tooltip title={t("faqs.content.13")} _open={open} _setOpen={setOpen} _index={9}>
                    <img onClick={() => setOpen(open === 9 ? -1 : 9)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[9])}</span>
              </BuyBox>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[10]?.classID)}>{t("buyRuby")} - 47 000 CLO</button>
                  <Tooltip title={t("faqs.content.14")} _open={open} _setOpen={setOpen} _index={10}>
                    <img onClick={() => setOpen(open === 10 ? -1 : 10)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[10])}</span>
              </BuyBox>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[11]?.classID)}>{t("buyAmethyst")} - 4 700 CLO</button>
                  <Tooltip title={t("faqs.content.15")} _open={open} _setOpen={setOpen} _index={11}>
                    <img onClick={() => setOpen(open === 11 ? -1 : 11)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[11])}</span>
              </BuyBox>
            </GridItem>
            <GridItem>
              <Box>
                <video controls autoPlay loop muted playsInline>
                  <source src={posters[12]?.image} type="video/mp4" />
                </video>
              </Box>
              <h4>{t("name.12")}</h4>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[12]?.classID)}>{t("buyEmerald")} - 470 000 CLO</button>
                  <Tooltip title={t("faqs.content.13")} _open={open} _setOpen={setOpen} _index={12}>
                    <img onClick={() => setOpen(open === 12 ? -1 : 12)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[12])}</span>
              </BuyBox>
              <BuyBox>
                <Box>
                  <button onClick={() => buy(posters[13]?.classID)}>{t("buyRuby")} - 47 000 CLO</button>
                  <Tooltip title={t("faqs.content.14")} _open={open} _setOpen={setOpen} _index={13}>
                    <img onClick={() => setOpen(open === 13 ? -1 : 13)} src="/images/info.png" alt='' />
                  </Tooltip>
                </Box>
                <span>{getSupply(posters[13])}</span>
              </BuyBox>
              <BuyBox>
                <Box>
                  <button>{t("exhibitionExclusive")}</button>
                </Box>
              </BuyBox>
            </GridItem>
          </Grid>
        )}
      </Box>
    </StyledContainer>
  );
};

const SpinnerBox = styled(Box)`
  margin-top: 2.5rem;
  svg {
    margin-left: auto;
    margin-right: auto;
  }
`

const BuyBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: grey;
  @media (min-width: 768px) {
    padding-top: 0.5rem;
  }
  @media (min-width: 768px) {
    padding-bottom: 0.5rem;
  }
  >div:first-of-type {
    display: flex;
    align-items: center;
    > button {
      border-radius: 9999px;
      background: rgba(253, 187, 42, 1);
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-weight: 700;
      color: white;
      border: none;
      margin-right: 8px;
      &:hover {
        text-decoration: underline;
        text-decoration-color: white;
      }
    }
  }
`;

const Grid = styled(Box)`
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;
  @media (min-width: 640px) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  @media (min-width: 1280px) {
    padding-left: 0;
    padding-right: 0;
  }
  > div:nth-of-type(4) {
    background: black;
    grid-column: span 2 / span 2;
  }
`;

const GridItem = styled(Box)`
  position: relative;
  grid-column: span 4 / span 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: rgba(243, 244, 246, 1);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  @media (min-width: 640px) {
    border-radius: 0.75rem;
  }
  > div:first-of-type {
    display: flex;
    align-items: center;
    > video {
      max-height: 24rem;
    }
  }
  > h4 {
    text-align: center;
    line-height: 1.75rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: rgba(55, 65, 81, 1);
  }
  > * + * {
    margin-top: 1rem;
  }
`;

const StyledContainer = styled(Box)`
  padding-top: 0;
  padding-bottom: 5rem;
  background: black;
  > div {
    width: 100%;
    @media (min-width: 640px) {
      max-width: 640px;
    }
    @media (min-width: 768px) {
      max-width: 768px;
    }
    @media (min-width: 1024px) {
      max-width: 1024px;
    }
    max-width: 72rem !important;
    margin-left: auto;
    margin-right: auto;
    > h2 {
      text-align: center;
      font-size: 2.25rem;
      line-height: 2.5rem;
      font-weight: 700;
      letter-spacing: -0.025em;
      color: white;
    }
    > p {
      color: rgba(253, 187, 42, 1);
      margin-top: 0.5rem;
      text-align: center;
      font-size: 1.125rem;
      line-height: 1.75rem;
      >a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
`;

export default Posters;

import MAIN_ABI from './MAIN_ABI.json'
import AUCTION_ABI from './AUCTION_ABI.json'

export const ROUTERS = {
    MAIN: {
        abi: MAIN_ABI,
        address: '0xd0725743f665B8041DcfbB0c4B55bbC3679Fb033',
    },
    AUCTION: {
        abi: AUCTION_ABI,
        address: '0x66aAA4C35243612947F58fC508b5301366e0712D',
    },
}
import React, { useContext } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { RefContext } from "contexts/RefContextProvider";

const Collections: React.FC<any> = ({ login, logout }) => {
  const { account } = useWeb3React();
  const { t } = useTranslation();
  const { faqRef, collectionInfoRef } = useContext(RefContext);
  const connect = async () => {
    if (!account) login();
    else logout();
  };
  return (
    <StyledContainer component="section">
      <Box display="flex">
        <Box>
          <video controls autoPlay loop muted playsInline>
            <source src="videos/montecarlo.mp4" type="video/mp4" />
          </video>
        </Box>
        <Box>
          <h2>{t("collections.0.title")}</h2>
          <ConnectWalletBox connected={account ? 1 : 0}>
            <button onClick={connect}>{!account ? t("connect") : t("disconnect")}</button>
          </ConnectWalletBox>
          <List>
            <li>
              <span>
                <span>✓</span>
              </span>
              {t("collections.0.subtitle.0")}
            </li>
            <li>
              <span>
                <span>✓</span>
              </span>
              {t("collections.0.subtitle.1")}
            </li>
            <li>
              <span>
                <span>✓</span>
              </span>
              <Trans i18nKey="collections.0.subtitle.2">
                {[<a key={0} href="#faq" onClick={() => faqRef.current.scrollIntoView({ behavior: "smooth" })}> </a>]}
              </Trans>
            </li>
            <li>
              <span>
                <span>✓</span>
              </span>
              <Trans i18nKey="collections.0.subtitle.3">
                {[<a key={0} href="#collectioninfo" onClick={() => collectionInfoRef.current.scrollIntoView({ behavior: "smooth" })}> </a>]}
              </Trans>
            </li>
          </List>
        </Box>
      </Box>
      <Box display="flex">
        <Box>
          <img src="images/slavic_epic.png" alt="" />
        </Box>
        <Box>
          <h2>{t("collections.1.title")}</h2>
          <BidBox>
            <button>
              <a href="https://opensea.io/collection/alphonse-mucha-emotion">{t("buyNow")}</a>
            </button>
          </BidBox>
          <List>
            <li>
              <span>
                <span>✓</span>
              </span>
              {t("collections.1.subtitle.0")}
            </li>
            <li>
              <span>
                <span>✓</span>
              </span>
              {t("collections.1.subtitle.1")}
            </li>
            <li>
              <span>
                <span>✓</span>
              </span>
              {t("collections.1.subtitle.2")}
            </li>
          </List>
        </Box>
      </Box>
    </StyledContainer>
  );
};

const List = styled.ul`
  margin: 0;
  padding: 0;
  line-height: 1.5rem;
  > li {
    position: relative;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0;
    text-align: left;
    color: white;
    a {
      color: white;
      text-decoration: underline;
    }
    > span {
      margin-right: 0.75rem;
      width: 1.5rem;
      height: 1.5rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 9999px;
      background: rgba(252, 211, 77, 1);
      > span {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 700;
      }
    }
  }
`;

const BidBox = styled(Box)`
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  @media (min-width: 768px) {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  > button {
    border: 0;
    border-radius: 9999px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: 700;
    background: rgba(253, 187, 42, 1);
    color: white;
    > a {
      text-decoration-color: white;
      color: white;
    }
  }
`;

const ConnectWalletBox = styled(Box)<any>`
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  @media (min-width: 768px) {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  > button {
    border: 0;
    border-radius: 9999px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: 700;
    background: ${({ connected }) => (connected ? "rgba(255,255,255,1)" : "rgba(253, 187, 42, 1)")};
    color: ${({ connected }) => (connected ? "black" : "white")};
    transition: background, color 0.5s;
    &:hover {
      text-decoration: underline;
      text-decoration-color: ${({ connected }) => (connected ? "rgba(0,0,0,1)" : "rgba(255,255,255,1)")};
    }
  }
`;

const StyledContainer = styled(Box)`
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  @media (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 6rem;
  }
  > div {
    /** -----------Main DIV--------------- */
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    line-height: 1.5rem;
    padding-bottom: 0;
  }
  /** ------------ Complete first row Posters-------------*/
  > div:not(:nth-of-type(2)) {
    @media (min-width: 768px) {
      flex-direction: row;
    }
    @media (min-width: 1024px) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    /** ---------------Poster picture-------------- */
    > div:first-of-type {
      position: relative;
      width: 100%;
      max-width: 28rem;
      margin-top: 1.25rem;
      margin-bottom: 1rem;
      margin-left: -1.25rem;
      background-repeat: no-repeat;
      background-size: contain;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;
      @media (min-width: 768px) {
        margin-top: 0;
        margin-left: 0;
        width: 50%;
        max-width: none;
      }
      @media (min-width: 1024px) {
        margin-bottom: 0;
      }
      @media (min-width: 1280px) {
        padding-left: 2.5rem;
      }
      > img {
        max-width: 100%;
        padding: 0.5rem;
        padding-right: 1.25rem;
        padding-left: 1.5rem;
        @media (min-width: 1280px) {
          padding-left: 4rem;
          padding-right: 5rem;
        }
      }

      > video {
        max-width: 70%;
        padding-top: 50px;
        padding-left: 1.5rem;
        @media (min-width: 1280px) {
          padding-left: 4rem;
          padding-right: 5rem;
        }
      }
    }

    /** --------------- Poster Text------------------*/
    > div:nth-of-type(2) {
      order: -9999;
      @media (min-width: 768px) {
        order: 0;
        width: 50%;
        padding-left: 2.5rem;
      }
      > h2 {
        margin: 0;
        color: white;
        line-height: 1.75rem;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.25;
        @media (min-width: 768px) {
          font-size: 1.5rem;
          line-height: 2rem;
        }
        @media (min-width: 1024px) {
          font-size: 1.875rem;
          line-height: 2.25rem;
        }
      }
      > p {
        margin: 0;
        color: rgb(253, 187, 42);
        padding-top: 1rem;
        padding-bottom: 2rem;
        line-height: 1.75rem;
        @media (min-width: 640px) {
          padding-right: 3rem;
        }
        @media (min-width: 1024px) {
          font-size: 1.125rem;
          line-height: 1.75rem;
        }
      }
    }
  }
  /** --------------- Complete second Row Epic------------------*/
  > div:nth-of-type(2) {
    margin-top: 0.5rem;
    @media (min-width: 768px) {
      margin-top: 5rem;
      flex-direction: row;
    }
    @media (min-width: 1024px) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    @media (min-width: 1280px) {
      margin-top: 0;
    }
    /** ---------------Poster picture-------------- */
    > div:first-of-type {
      position: relative;
      width: 100%;
      max-width: 28rem;
      margin-top: 1.25rem;
      margin-bottom: 1rem;
      margin-left: -1.25rem;
      background-repeat: no-repeat;
      background-size: contain;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;
      @media (min-width: 768px) {
        margin-top: 0;
        margin-left: 0;
        width: 50%;
        max-width: none;
      }
      @media (min-width: 1024px) {
        margin-bottom: 0;
      }
      @media (min-width: 1280px) {
        padding-left: 2.5rem;
      }
      > img {
        max-width: 100%;
        padding: 0.5rem;
        padding-right: 1.25rem;
        padding-left: 1.5rem;
        @media (min-width: 1280px) {
          padding-left: 4rem;
          padding-right: 5rem;
        }
      }
    }
    /** ------------------------- Slav epic Text--------------- */
    > div:nth-of-type(2) {
      order: -9999;
      @media (min-width: 768px) {
        order: 0;
        width: 50%;
        padding-left: 2.5rem;
      }
      > h2 {
        margin: 0;
        color: white;
        line-height: 1.75rem;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.25;
        @media (min-width: 768px) {
          font-size: 1.5rem;
          line-height: 2rem;
        }
        @media (min-width: 1024px) {
          font-size: 1.875rem;
          line-height: 2.25rem;
        }
      }
      > p {
        margin: 0;
        color: rgb(253, 187, 42);
        padding-top: 1rem;
        padding-bottom: 2rem;
        line-height: 1.75rem;
        @media (min-width: 640px) {
          padding-right: 3rem;
        }
        @media (min-width: 1024px) {
          font-size: 1.125rem;
          line-height: 1.75rem;
        }
      }
    }
  }
`;

export default Collections;

import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Box, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import web3 from "web3";
import { useAuctionContract, useMainContract } from "hooks/useContract";
import { setOpenPolicyModal } from "redux/reducers/ModalReducer";
import { setAccepted } from "redux/reducers/NetworkReducer";

const PolicyModal: React.FC = () => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const { t } = useTranslation();
  const history = useHistory();
  const mainContract = useMainContract();
  const auctionContract = useAuctionContract();
  const open = useSelector((store: any) => store.ModalReducer.openPolicyModal);
  const { classId } = useSelector((store: any) => store.ModalReducer.details);

  const close = async () => {
    dispatch(setOpenPolicyModal({}));
  };

  const buy = async () => {
    dispatch(setOpenPolicyModal({}));
    dispatch(setAccepted({ accepted: true }));
    const propStr = await mainContract?.getClassProperties(classId).call();
    try {
      const prop = JSON.parse(propStr[1]);
      await auctionContract
        ?.buyNFT(classId)
        .send({ from: account, value: web3.utils.toWei(prop.price) });
      history.push("/mynfts");
    } catch (e: any) {
      if (e.code) alert(JSON.stringify(e));
      else alert(e);
    }
  };

  return (
    <Modal open={open} onClose={close} disableScrollLock>
      <StyledContainer>
        <Title mb={2}>
          <h2>{t("policy.title")}</h2>
        </Title>
        <span>
          <Trans i18nKey="policy.content">
            {[
              <a key={0} href="/legal/Terms.pdf" target="_blank">
                {" "}
              </a>,
              <a key={1} href="/legal/Privacy.pdf" target="_blank">
                {" "}
              </a>,
            ]}
          </Trans>
        </span>
        <Actions>
          <button onClick={buy}>{t("policy.accept")}</button>
          <button onClick={close}>{t("policy.reject")}</button>
        </Actions>
      </StyledContainer>
    </Modal>
  );
};

const Actions = styled(Box)`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  > button {
    font-weight: 700;
    font-size: 14px;
    border-radius: 9999px;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    &:hover {
      text-decoration: underline;
    }
  }
  > button + button {
    margin-left: 8px;
  }
  > button:first-of-type {
    background: rgba(253, 187, 42, 1);
    color: white;
  }
  > button:nth-of-type(2) {
    background: #111;
    color: rgba(255, 255, 255, 1);
  }
`;

const Title = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  > h2 {
    margin-left: 10px;
  }
`;

const StyledContainer = styled(Box)<any>`
  outline: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #edefef;

  display: flex;
  flex-direction: column;
  min-width: 360px;
  padding: 40px;
  @media (max-width: 768px) {
    min-width: unset;
    width: calc(100% - 60px);
    padding: 20px;
  }
  max-height: calc(100% - 100px);
  overflow-y: auto;
  > svg {
    cursor: pointer;
    position: absolute;
    right: 13.9px;
    top: 12.1px;
  }
  a {
    font-weight: 600;
    color: black;
    text-decoration: underline;
    text-decoration-color: black;
  }
`;

export default PolicyModal;

import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Box, Modal, TextField, Tooltip } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";

import { setOpenTransferModal } from "redux/reducers/ModalReducer";
import { useMainContract } from "hooks/useContract";

const TransferModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const mainContract = useMainContract();
  const open = useSelector(
    (store: any) => store.ModalReducer.openTransferModal
  );
  const details = useSelector((store: any) => store.ModalReducer.details);
  const [doing, setDoing] = useState(false);
  const [receiver, setReceiver] = useState("");

  const close = async () => {
    dispatch(setOpenTransferModal({}));
  };

  const confirm = async () => {
    setDoing(true);
    if(!doing) {
      try {
        await mainContract
          ?.silentTransfer(receiver, details.tokenID)
          .send({ from: account });
        dispatch(setOpenTransferModal({ transferred: true }));
      } catch (e:any) {
        if(e.code) alert(JSON.stringify(e))
        else alert(e)
      }
    }
    else alert(t("msg.running"));
    setDoing(false);
  };

  return (
    <Modal open={open} onClose={close} disableScrollLock>
      <StyledContainer>
        <Tooltip title={account ?? ""} enterTouchDelay={0} leaveDelay={10000} leaveTouchDelay={0}>
          <Text1>{t("from")}:<br />{account}</Text1>
        </Tooltip>
        <Box mt={2}>
          <FullTextField
            id="standard-number"
            label="To"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            value={receiver}
            onChange={(e) => setReceiver(e.target.value)}
          />
        </Box>
        <Actions mt={1}>
          <BuyBox>
            <button onClick={confirm} disabled={receiver === ""}>
              {t("confirm")}
            </button>
          </BuyBox>
        </Actions>
      </StyledContainer>
    </Modal>
  );
};

const FullTextField = styled(TextField)`
  width: 100%;
`

const Text1 = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BuyBox = styled(Box)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: right;
  @media (min-width: 768px) {
    padding-top: 0.5rem;
  }
  @media (min-width: 768px) {
    padding-bottom: 0.5rem;
  }
  > button {
    border-radius: 9999px;
    background: rgba(253, 187, 42, 1);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: 700;
    color: white;
    text-decoration-color: white;
    border: none;
    cursor: pointer;
    &:hover:not(:disabled) {
      text-decoration: underline;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

const Actions = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`;

const StyledContainer = styled(Box)<any>`
  outline: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #edefef;

  display: flex;
  flex-direction: column;
  min-width: 360px;
  padding: 40px 40px 20px 40px;
  @media (max-width: 768px) {
    min-width: unset;
    width: calc(100% - 60px);
    padding: 20px 20px 10px 20px;
  }
  max-height: calc(100% - 100px);
  overflow-y: auto;
  > svg {
    cursor: pointer;
    position: absolute;
    right: 13.9px;
    top: 12.1px;
  }
`;

export default TransferModal;

import React, { useContext } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { RefContext } from "contexts/RefContextProvider";

const FAQ: React.FC = () => {
  const { t } = useTranslation();
  const { faqRef } = useContext(RefContext);

  return (
    <StyledContainer component="section" ref={faqRef}>
      <Box>
        <h2>{t("faqs.title")}</h2>
        <Grid>
          <Box color="white">
            <Title component="h5">{t("faqs.subtitle.0")}</Title>
            <Text1 component="p">
              <Trans i18nKey="faqs.content.0">
                {[<a key={0} href="https://bridge.soy.finance/"> </a>, <a key={1} href="https://youtu.be/pCh_qC_2ROY"> </a>]}
              </Trans>
            </Text1>
            <Text1 component="p">
            <Trans i18nKey="faqs.content.1">
            {[<a key={0} href="https://docs.callisto.network/callisto-network/tutorials/how-to-buy-callisto-with-your-credit-card"> </a>,<a key={0} href="https://bridge.soy.finance/"> </a>]}
            </Trans>
            </Text1>
            <Text1 component="p">
              <Trans i18nKey="faqs.content.2">
                {[<a key={0} href="https://callisto.network/buy-clo-in-3-quick-steps/"> </a>, <a key={1} href="https://soy.finance/"> </a>]}
              </Trans>
            </Text1>
            <Title component="h5">{t("faqs.subtitle.1")}</Title>
            <Text2 component="p">{t("faqs.content.3")}</Text2>
            <Title component="h5">{t("faqs.subtitle.2")}</Title>
            <Text2 component="p">{t("faqs.content.4")}</Text2>
            <Title component="h5">{t("faqs.subtitle.3")}</Title>
            <Text2 component="p">{t("faqs.content.5")}</Text2>
          </Box>
          <Box color="white">
            <Title component="h5">{t("faqs.subtitle.4")}</Title>
            <Text2 component="p">{t("faqs.content.6")}</Text2>
            <Title component="h5">{t("faqs.subtitle.5")}:</Title>
            <Text1 component="p">{t("faqs.content.7")}</Text1>
            <Text1 component="p">{t("faqs.content.8")}</Text1>
            <Text1 component="p">
              <Trans i18nKey="faqs.content.9">
                {[<a key={0} href="https://explorer.callisto.network/address/0xd0725743f665B8041DcfbB0c4B55bbC3679Fb033/contracts"> </a>]}
              </Trans>
            </Text1>
            <Title component="h5">{t("faqs.subtitle.6")}</Title>
            <Text1 component="p">
              <span>EMERALD</span><br/>
              <Trans i18nKey="faqs.content.10">
                {[<a key={0} href="https://imucha.com/eshop"> </a>]}
              </Trans>
            </Text1>
            <Text1 component="p">
              <span>RUBY</span><br/>
              <Trans i18nKey="faqs.content.11">
                {[<a key={0} href="https://imucha.com/eshop"> </a>]}
              </Trans>
            </Text1>
            <Text1 component="p">
              <span>AMETHYST</span><br/>
              {t("faqs.content.12")}
            </Text1>
          </Box>
        </Grid>
      </Box>
    </StyledContainer>
  );
};

const Text2 = styled(Box)`
  color: rgba(255, 255, 255, 1);
`;

const Text1 = styled(Box)`
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0.75rem;
  a {
    color: #fff;
    font-weight: bold;
    letter-spacing: 0.03em;
  }
`;

const Title = styled(Box)`
  margin-top: 2.5rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  font-size: 16px;
  color: rgba(253, 187, 42, 1);
`;

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0;
  color: rgba(75, 85, 99, 1);
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (min-width: 768px) {
    gap: 4rem;
  }
`;

const StyledContainer = styled(Box)`
  padding-top: 6rem;
  padding-bottom: 6rem;
  background: black;
  > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
    padding-left: 2rem;
    padding-right: 2rem;
    @media (min-width: 1024px) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    > h2 {
      margin-bottom: 1rem;
      line-height: 1.75rem;
      font-size: 1.25rem;
      font-weight: 700;
      color: white;
      @media (min-width: 768px) {
        font-size: 1.875rem;
        line-height: 2.25rem;
      }
    }
  }
`;

export default FAQ;

export enum ConnectorNames {
    Injected = "injected",
    WalletConnect = "walletconnect",
    BSC = "bsc",
}

export interface Config {
    id: number;
    name: string;
    connectorId: ConnectorNames;
}

export const connections: Config[] = [
    {
        id  : 0,
        name: 'Metamask',
        connectorId: ConnectorNames.Injected,
    },
    {
        id  : 1,
        name: 'Wallet Connect',
        connectorId: ConnectorNames.WalletConnect,
    },
];

export const connectorLocalStorageKey = "connectorId";
export const TRANSLATIONS_EN = {
  policy: {
    title: "Our policy",
    content: `I declare that I have read, understood and agree to the <0>TERMS AND CONDITIONS</0> (hereinafter referred to as "TC").<br/><br/>
    I further declare that I am not a citizen, resident or a company incorporated or registered in any of the prohibited jurisdictions as defined in TC.<br/><br/>
    I also declare that in the case of any purchase of NFT through the website, I expressly agree to the immediate delivery of the digital content before the expiry of the withdrawal period, i.e. before the expiry of the period of 14 days from the date of conclusion of the contract, and I hereby acknowledge that I cannot exercise my right to withdraw from the concluded contract for the supply of digital content for this reason within the period of 14 days.<br/><br/>
    I also declare that I have read the <1>PRIVACY NOTICE</1>.`,
    accept: "Accept",
    reject: "Reject",
  },
  cookie: {
    title: "Cookies",
    details: "Details",
    acceptButton: "Accept all cookies",
    declineButton: "Decline",
    description: "This website uses cookies to enhance the user experience.",
    welcomeText: "The use of cookies allows us to offer you content and services that best match your needs and interests. Cookies make it possible to record information about your visit to the Website, and thanks to their use, your next visit will be easier and faster.",
  },
  en: "en",
  cz: "cz",
  home: "Home",
  email: "E-mail",
  news: "News",
  faq: "FAQ",
  newsletter: "Newsletter",
  tickets: "Tickets",
  eshop: "E-shop",
  mynfts: "My NFTs",
  mynft: "My NFT",
  introduce: {
    title: "NFT COLLECTION",
    description: "Alphonse Mucha's magnificent work has taken on a new dimension thanks to modern technology.",
  },
  collections: {
    0: {
      title: "Posters collection",
      subtitle: {
        0: "Animated posters",
        1: "Using CallistoNFT standard",
        2: "Multiple tiers with different <0>benefits</0>",
        3: "<0>Read more about Posters Colelction</0>",
      }
    },
    1: {
      title: "Slavic Epic collection",
      subtitle: {
        0: "Available on OpenSea",
        1: "NFT owners will gain access to the Emerald Club, where they can drawn on the benefits asociated with this membership.",
        2: "Only one per artwork",
      }
    },
  },
  buyNow: "Buy now",
  connect: "Connect Wallet",
  disconnect: "Disconnect",
  posters: {
    title: "The Posters collection",
    description: "NFTs on the <0>callisto.network</0>",
  },
  buyEmerald: "Buy Emerald",
  buyRuby: "Buy Ruby",
  buyAmethyst: "Buy Amethyst",
  exhibitionExclusive: "Exhibition exclusive",
  collectionInfo: {
    title: "About the Posters Collection",
    content: `Alphonse Mucha's world-famous posters have taken on a new dimension thanks to modern technology. The girls from the art nouveau style posters come to life and you can admire their beauty in the best quality on a large screen in your home.\n\nIn this NFT collection you will find 5 moving posters by Alfons Mucha. The individual animations highlight the energy and beauty that Alfonso Mucha managed to create more than 100 years ago.\n\nEach animation is elaborated to the smallest detail, but at the same time the unique mood of the individual posters is preserved. Small movements in the animations are created very sensitively so as not to disturb the mood of the individual works.\n\nNFTs from this collection are offered on our own NFT market, which is based on the Callisto Network blockchain. This blockchain was chosen because of its high security and ability to sell NFT in individual editions. This is made possible by low network fees and the new CallistoNFT standard. NFT are offered in 3 different editions, the names of which are based on Mucha's posters: Emerald, Ruby and Amethyst. NFTs from this collection can be purchased at a set price on our NFT market. The offer is not limited in time. Only the amount of NFT in each edition is limited.\n\nVarious benefits are prepared for NFT owners according to the respective edition. For example, owners of the highest Emerald Edition gain access to the Emerald Club, where they can reap the benefits of this membership. For example, they can participate in all exhibitions and events organized by the iMucha project free of charge. They also receive a discount on purchases in the iMucha e-shop. A detailed description of the benefits for each edition and the conditions of their use can then be found on the website nft.muchaemotion.com.`,
  },
  faqs: {
    title: "Frequently Asked Questions",
    subtitle: {
      0: "How to get CLO (Callisto Network native cryptocurrency)",
      1: "How to buy (mint) NFT?",
      2: "Do I need any registration?",
      3: "Where I can see my NFTs?",
      4: "Can I transfer my NFT to a different address?",
      5: "Smart contract details",
      6: "Benefits and utility",
    },
    content: {
      0: "1) You can buy CLO directly by the most secured <0>SOY Finance Bridge</0> with ETH, BNB, ETC, USDT, BTT, CAKE, SHIB check video guide <1>here.</1>",
      1: "2) You can <0>buy easily BNB with your credit card</0> and then use the <1>bridge</1> to buy CLO coins (don't worry, it's really easy)",
      2: "3) You can get <0>CLO on Bitfinex</0> or you can use the most secured decentralized exchange <1>SOY Finance.</1>",
      3: "You need a Metamask wallet and enough Callisto coin (CLO) to cover the minting cost. Everything is described in How to Get CLO.",
      4: "No, you can just connect your wallet and start shopping :).",
      5: `You can see and download your NFTs on the “My NFTs” page here. Your NFTs are in your wallet, you can see the ART token if you search your address in explorer.callisto.network. At this moment Callisto team is working on implementation of NFTs directly to the explorer. The NFTs should are also visible in Absolute wallet application.`,
      6: `Yes, you can use a “transfer” button on the “My NFT” page. When you send the NFT, please make sure you are connected to the Callisto Network, otherwise the NFT will not show up in the new wallet. Because of security features built into the new CallistoNFT Standard, you cannot accidentally lose your NFT by sending it to the wrong wallet address, which is a risk with other NFT standards.`,
      7: "Blockchain: Callisto Network",
      8: "Standard: Callisto NFT (ERC-721 compatible)",
      9: "Contract address: <0>Callisto Network Explorer</0>",
      10: "Collector becomes an Emerald club member by owning an Alphonse Mucha eMOTION NFT belonging to the Emerald edition. Emerald club member is entitled to access every exhibition and social gathering organized or coorganized by iMucha for free if not stated otherwise in the terms and conditions of the corresponding event. Emerald club member can use his/hers abovementioned right once for every event. An Emerald club member is also entitled to 20% discount on all goods and services purchased via <0>https://imucha.com/eshop</0> and various other Perks that will or may be disclosed by iMucha.",
      11: "Collector becomes a Ruby club member by owning an Alphonse Mucha eMOTION NFT belonging to the Ruby edition. Ruby club member is entitled to 20% discount on all goods and services purchased via <0>https://imucha.com/eshop</0> and various other Perks that will or may be disclosed by iMucha",
      12: "Collector becomes an Amethyst club member by owning an Alphonse Mucha eMOTION NFT belonging to the Amethyst edition. Amethyst club member is entitled to various Perks that will or may be disclosed by iMucha.",
      13: "Collector becomes an Emerald club member by owning an Alphonse Mucha eMOTION NFT belonging to the Emerald edition. Emerald club member is entitled to access every exhibition and social gathering organized or coorganized by iMucha for free if not stated otherwise in the terms and conditions of the corresponding event. Emerald club member can use his/hers abovementioned right once for every event. An Emerald club member is also entitled to 20% discount on all goods and services purchased via https://imucha.com/eshop and various other Perks that will or may be disclosed by iMucha.",
      14: "Collector becomes a Ruby club member by owning an Alphonse Mucha eMOTION NFT belonging to the Ruby edition. Ruby club member is entitled to 20% discount on all goods and services purchased via https://imucha.com/eshop and various other Perks that will or may be disclosed by iMucha",
      15: "Collector becomes an Amethyst club member by owning an Alphonse Mucha eMOTION NFT belonging to the Amethyst edition. Amethyst club member is entitled to various Perks that will or may be disclosed by iMucha.",
    }
  },
  noItems: "No items",
  download: "Download",
  transfer: "Transfer",
  from: "From",
  confirm: "Confirm",
  name: {
    0: "Monte Carlo (1897)",
    1: "Monte Carlo (1897)",
    2: "Monte Carlo (1897)",
    3: "Carnation (1898)",
    4: "Carnation (1898)",
    5: "Carnation (1898)",
    6: "Summer (1899)",
    7: "Summer (1899)",
    8: "Summer (1899)",
    9: "The lady with camelias (1896)",
    10: "The lady with camelias (1896)",
    11: "The lady with camelias (1896)",
    12: "Perfecta cykly (1902)",
    13: "Perfecta cykly (1902)",
    14: "Perfecta cykly (1902)",
  },
  edition: {
    Emerald: "Emerald | #<0></0><br/>",
    Ruby: "Ruby | #<0></0><br/>",
    Amethyst: "Amethyst | #<0></0><br/>",
  },
  copyright: "© 2022 Mucha eMOTION",
  footer: {
    terms: "Terms",
    privacy: "Privacy",
  },
  msg: {
    install: "Please install Metamask.",
    running: "Currently running.",
    gettingPrice: "Getting price.",
    connect: "Please connect wallet.",
    wrongConnector: "The connector config is wrong.",
  }
};
